/** @jsx jsx */
import { jsx, useColorMode, ThemeProvider } from 'theme-ui';
import { graphql } from 'gatsby';
import { Social } from '../data/social/social';
import { ProgramLayout } from '../layouts/ProgramLayout';
import { H3, P } from '../components/Text';
import ReactMarkdown from 'react-markdown';
import moment from 'moment-timezone';
import { DayThemes, TimesStack } from './ProgramDay';
import { Container } from '../components/Container';
import { Box, Flex } from 'rebass';
import React from 'react';

interface SocialEventsProps {
  pageContext: {
    socialEvents: Social[];
  };
  data: any;
}

interface SingleEventProps {
  program: any;
  localStartMoments: moment.Moment[];
  speakerImages?: Record<string, string>;
}

const Triangle = () => (
  <Box
    sx={{
      width: 0,
      height: 0,
      display: 'box',
      borderStyle: 'solid',
      borderColor: 'text',
      borderWidth: '0 0 24px 24px',
      borderTopColor: 'transparent',
      borderRightColor: 'transparent',
      borderBottomColor: 'text',
      borderLeftColor: 'transparent',
    }}
  />
);

const allDayStyle = {
  backgroundColor: 'text',
  color: 'background',
  writingMode: 'vertical-lr',
  textAlign: 'center',
  content: '"ALL DAY"',
  fontFamily: 'body',
  ml: -3,
  my: '-9px',
  pt: 1,
  position: 'relative',
  left: '-17px',
};

const SingleSocialEvent: React.FC<SingleEventProps> = ({
  program,
  localStartMoments,
  speakerImages,
}) => {
  const allDay = program.duration === 'allday' ? allDayStyle : {};
  return (
    <li
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: ['column', 'column', 'row'],
        mb: [3, 3, 2],
      }}
    >
      <Box
        sx={{
          flex: '1',
          py: [2, 2, 0],
          px: [3, 3, 0],
          borderColor: 'text',
          border: ['solid 1px', 'solid 1px', 'none'],
          borderBottom: 'none',
        }}
      >
        <TimesStack times={localStartMoments} color="text" />
        <P sx={{ fontWeight: 'bold' }}>{program.format}</P>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: '3',
          px: 3,
          py: 2,
          borderStyle: 'solid',
          borderColor: 'text',
          borderWidth: '1px',
          '&:before': allDay,
        }}
      >
        <Flex
          sx={{
            flexDirection: ['column', 'column', 'row'],
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ flexShrink: 1 }}>
            <h3
              sx={{
                display: 'flex',
                variant: 'text.body',
                fontSize: 3,
                fontWeight: 'bold',
                color: 'text',
                my: 0,
              }}
            >
              {program.title}
              {program.must_register === 'TRUE' && (
                <Box sx={{ ml: 2, mt: 1 }}>
                  <Triangle />
                </Box>
              )}
            </h3>
            <P sx={{ color: 'text', mb: 4 }}>
              <span>
                {program.speaker_1_name}
                {program.speaker_2_name ? `, ${program.speaker_2_name}` : ''}
              </span>
            </P>
            {program.resume && (
              <ReactMarkdown
                source={program.resume}
                linkTarget="_blank"
                sx={{
                  variant: 'text.body',
                  li: { listStyle: 'disc', ml: 3 },
                  strong: { fontWeight: 700 },
                  a: { variant: 'variants.link' },
                }}
              />
            )}
          </Box>
          <Flex
            sx={{
              flexDirection: ['row', 'row', 'column'],
              flexShrink: 0,
              ml: [0, 0, 2],
              width: ['100%', '100%', 'auto'],
              justifyContent: ['space-between', 'flex-start', 'flex-start'],
            }}
          >
            <Flex
              sx={{
                justifyContent: ['flex-start', 'flex-start', 'space-between'],
                alignItems: ['flex-end', 'flex-end', 'auto'],
              }}
            >
              {/* maybe tags an go here? */}
            </Flex>
            {speakerImages && program.speaker_1_photo && (
              <Flex
                sx={{
                  flexDirection: ['row', 'row', 'column'],
                  height: '100%',
                  minWidth: '118px',
                  justifyContent: 'flex-end',
                }}
              >
                {program.speaker_1_photo && (
                  <img
                    srcSet={
                      speakerImages[program.speaker_1_photo] ??
                      speakerImages['placeholder.jpg']
                    }
                    sx={{
                      width: '118px',
                      height: '118px',
                      alignSelf: 'flex-end',
                    }}
                  />
                )}
                {program.speaker_2_photo && (
                  <img
                    srcSet={
                      speakerImages[program.speaker_2_photo] ??
                      speakerImages['placeholder.jpg']
                    }
                    sx={{
                      mt: [0, 0, 2],
                      ml: [2, 0, 0],
                      width: '118px',
                      height: '118px',
                      alignSelf: 'flex-end',
                    }}
                  />
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
    </li>
  );
};

interface SocialEventsListProps {
  programs: Social[];
  tzOffset: number;
  speakerImages: Record<string, string>;
}

const SocialEventsList: React.FC<SocialEventsListProps> = ({
  programs,
  tzOffset,
  speakerImages,
}) => {
  return (
    <ul>
      {programs.map((p: any, i: number) => {
        const startDates = [p.start_date_1, p.start_date_2, p.start_date_3];
        const localStartMoments = startDates
          .filter(d => d !== '')
          .map(d => moment(d + '-05:00').utcOffset(tzOffset));

        return (
          <SingleSocialEvent
            program={p}
            localStartMoments={localStartMoments}
            key={i}
          />
        );
      })}
    </ul>
  );
};

const SocialTemplate: React.FC<SocialEventsProps> = ({
  pageContext: { socialEvents },
  data,
}) => {
  const speakerImages = data.socialImages.edges.reduce(
    (acc: any, { node }: any) => {
      acc[node.relativePath.split('/').pop()] =
        node.childImageSharp.fluid.srcSet;
      return acc;
    },
    {},
  );

  const programByDays = socialEvents.reduce<Record<string, Social[]>>(
    (acc, program) => {
      if (!program.day) {
        return acc;
      }
      if (!acc[program.day]) {
        acc[program.day] = [];
      }
      acc[program.day].push(program);
      return acc;
    },
    {},
  );

  return (
    <ProgramLayout programDays={['0', '1', '2', '3']}>
      {({ tzOffset }) => (
        <Container>
          <Flex>
            <Triangle />
            <P sx={{ ml: 2 }}>Sign up required</P>
          </Flex>
          {Object.keys(programByDays).map(eventsByDay => {
            const sortedEvents = programByDays[eventsByDay]
              .sort((a: any, b: any) => a.time_start - b.time_start)
              .filter((p: any) => p.active !== 'FALSE');

            return (
              <React.Fragment key={eventsByDay}>
                <H3 sx={{ fontFamily: 'heading' }}>
                  {eventsByDay === '0'
                    ? 'Workshop day'
                    : `Day ${eventsByDay} - ${DayThemes[eventsByDay]}`}
                </H3>
                <SocialEventsList
                  programs={sortedEvents}
                  tzOffset={tzOffset}
                  speakerImages={speakerImages}
                />
              </React.Fragment>
            );
          })}
        </Container>
      )}
    </ProgramLayout>
  );
};

export default SocialTemplate;

export const query = graphql`
  query findAllSocialEventsImages {
    socialImages: allFile(filter: { relativeDirectory: { eq: "social" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid {
              srcSet
            }
          }
        }
      }
    }
  }
`;
